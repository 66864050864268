import * as i0 from '@angular/core';
import { TemplateRef, Type, Pipe, inject, ViewContainerRef, Directive, Input, input, Injector, effect, untracked, isSignal } from '@angular/core';
import { isEqual, assign } from 'lodash-es';
const crmIsRenderItem = value => {
  if (!value || typeof value !== 'object' || !('content' in value)) {
    return false;
  }
  const content = value.content;
  return content instanceof TemplateRef || content instanceof Type;
};
class CrmIsRenderItemPipe {
  transform(value) {
    return crmIsRenderItem(value) ? value : undefined;
  }
  static {
    this.ɵfac = function CrmIsRenderItemPipe_Factory(t) {
      return new (t || CrmIsRenderItemPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsRenderItem",
      type: CrmIsRenderItemPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsRenderItemPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsRenderItem'
    }]
  }], null, null);
})();
/**
 * @deprecated
 *
 * use CrmIsRenderItemPipe directly
 * @see CrmIsRenderItemPipe
 */
class CrmAsRenderItemPipe {
  transform(value) {
    return value;
  }
  static {
    this.ɵfac = function CrmAsRenderItemPipe_Factory(t) {
      return new (t || CrmAsRenderItemPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmAsRenderItem",
      type: CrmAsRenderItemPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAsRenderItemPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmAsRenderItem'
    }]
  }], null, null);
})();
const crmIsRenderItemEqual = (a, b) => a.content === b.content && isEqual(a.contentContext, b.contentContext);
class CrmRendererDirective {
  constructor() {
    this.viewContainerRef = inject(ViewContainerRef);
  }
  get instance() {
    return this._componentRef.instance;
  }
  ngOnChanges() {
    this.loadComponent();
  }
  loadComponent() {
    if (this.static && (this._embeddedRef || this._componentRef)) {
      return;
    }
    const {
      content,
      contentContext = {}
    } = this.renderItem ?? {};
    const context = assign(contentContext, this.additionalContext ?? {});
    if (!content) {
      return;
    }
    if (content instanceof TemplateRef) {
      this.resolveTemplate(content, context);
    } else {
      this.resolveComponent(content, context);
    }
  }
  resolveTemplate(content, context) {
    if (this._embeddedRef) {
      this._embeddedRef.destroy();
    }
    this._embeddedRef = this.viewContainerRef.createEmbeddedView(content, context);
  }
  resolveComponent(content, context) {
    if (this._componentRef) {
      this._componentRef.destroy();
    }
    this._componentRef = this.viewContainerRef.createComponent(content);
    Object.entries(context).forEach(([key, value]) => {
      // TODO: test -> this._componentRef.setInput(key, value)
      this._componentRef.instance[key] = value;
    });
  }
  static {
    this.ɵfac = function CrmRendererDirective_Factory(t) {
      return new (t || CrmRendererDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmRendererDirective,
      selectors: [["", "crmRenderer", ""]],
      inputs: {
        renderItem: [i0.ɵɵInputFlags.None, "crmRenderer", "renderItem"],
        additionalContext: "additionalContext",
        static: "static"
      },
      exportAs: ["crmRenderer"],
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmRendererDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmRenderer]',
      exportAs: 'crmRenderer'
    }]
  }], null, {
    renderItem: [{
      type: Input,
      args: ['crmRenderer']
    }],
    additionalContext: [{
      type: Input
    }],
    static: [{
      type: Input
    }]
  });
})();
class CrmRenderer2Directive {
  constructor() {
    this.render = input.required({
      alias: 'crmRenderer2'
    });
    this.context = input();
    this.ref = inject(ViewContainerRef);
    this.inj = inject(Injector);
    effect(() => {
      const render = this.render();
      const context = this.context();
      untracked(() => this.initRender(render, context ?? {}));
    });
  }
  initRender(render, ctx) {
    if (render instanceof TemplateRef) {
      this.renderTemplate(render, ctx);
    } else {
      this.renderComponent(render, ctx);
    }
  }
  renderTemplate(tmpl, ctx) {
    this.cmpRef = undefined;
    this.tmplRef?.destroy();
    this.tmplRef = this.ref.createEmbeddedView(tmpl, ctx, {
      injector: this.inj
    });
    this.tmplRef.markForCheck();
  }
  renderComponent(cmp, ctx) {
    this.tmplRef = undefined;
    this.cmpRef?.destroy();
    this.cmpRef = this.ref.createComponent(cmp, {
      injector: this.inj
    });
    this.updateComponentContext(this.cmpRef, ctx);
  }
  updateComponentContext(ref, ctx) {
    Object.entries(ctx).forEach(([key, value]) => {
      const prop = ref.instance[key];
      if (isSignal(prop) && 'set' in prop && typeof prop.set === 'function') {
        prop.set(value);
      } else {
        ref.instance[key] = value;
      }
    });
    ref.changeDetectorRef.markForCheck();
  }
  static {
    this.ɵfac = function CrmRenderer2Directive_Factory(t) {
      return new (t || CrmRenderer2Directive)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmRenderer2Directive,
      selectors: [["", "crmRenderer2", ""]],
      inputs: {
        render: [i0.ɵɵInputFlags.SignalBased, "crmRenderer2", "render"],
        context: [i0.ɵɵInputFlags.SignalBased, "context"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmRenderer2Directive, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmRenderer2]'
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmAsRenderItemPipe, CrmIsRenderItemPipe, CrmRenderer2Directive, CrmRendererDirective, crmIsRenderItem, crmIsRenderItemEqual };
