import * as i0 from '@angular/core';
import { InjectionToken, inject, Injector, Injectable, APP_INITIALIZER, NgModule } from '@angular/core';
import { CrmHttpParamTransform, CrmConfigService, CrmNetworkClientToken, CrmHttpParameterCodecToken, CrmModuleConfigToken, CrmNetworkClientModule } from 'common-module/core';
import { compact, omitBy, isNil, assign, cloneDeep } from 'lodash-es';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
class CrmEndpoint {
  constructor(params) {
    this.params = params;
  }
  get config() {
    return this.params.config;
  }
  get options() {
    return this.params.options;
  }
  get client() {
    return this.params.client;
  }
  get requestTransformer() {
    return this.params.requestTransformer;
  }
  get responseTransformer() {
    return this.params.responseTransformer;
  }
  get httpEncoder() {
    return this.params.httpEncoder;
  }
  endpoint(params) {
    return `${this.config.baseUrl}/${this.buildEndpointPath(params)}`;
  }
  buildEndpointPath(params) {
    const path = this.options.endpointName;
    const pathSegments = path.split('/');
    const pathKeys = pathSegments.filter(key => key.startsWith(':'));
    if (pathKeys.length === 0) {
      return path;
    }
    const {
      pathParams = {}
    } = params;
    const missingKeys = pathKeys.filter(key => !pathParams[key.substring(1)]);
    if (missingKeys.length) {
      throw Error(`Missing params for keys: '${missingKeys.join(', ')}'`);
    }
    return pathSegments.map(segment => {
      if (segment.startsWith(':')) {
        return pathParams[segment.substring(1)];
      }
      return segment;
    }).join('/');
  }
  list(options) {
    const {
      pathParams,
      ...rest
    } = options ?? {};
    return this.client.get(this.endpoint({
      pathParams
    }), this.getReqOptions(rest)).pipe(map(response => ({
      ...response,
      data: response.data.map(this.responseTransformer.transform)
    })));
  }
  listAll(options) {
    const {
      params = {},
      pathParams = {},
      ...rest
    } = options ?? {};
    const listAllLimit = this.options.listAllLimit ?? 20;
    return this.list({
      ...rest,
      params: {
        ...params,
        $limit: listAllLimit
      },
      pathParams
    }).pipe(switchMap(response => {
      if (response.total <= listAllLimit) {
        return of(response.data);
      }
      return this.list({
        ...rest,
        params: {
          ...params,
          $limit: response.total
        },
        pathParams
      }).pipe(map(response2 => response2.data));
    }));
  }
  create(body, options) {
    const {
      pathParams = {},
      ...rest
    } = options ?? {};
    return this.client.post(this.endpoint({
      pathParams
    }), this.requestTransformer.transform(body), this.getReqOptions(rest)).pipe(map(this.responseTransformer.transform));
  }
  read(id, options) {
    const {
      pathParams,
      ...rest
    } = options ?? {};
    return this.client.get(`${this.endpoint({
      pathParams
    })}/${id}`, this.getReqOptions(rest)).pipe(map(this.responseTransformer.transform));
  }
  update(id, body, options) {
    const {
      pathParams,
      ...rest
    } = options ?? {};
    return this.client.patch(`${this.endpoint({
      pathParams
    })}/${id}`, this.requestTransformer.transform(body), this.getReqOptions(rest)).pipe(map(this.responseTransformer.transform));
  }
  delete(id, options) {
    const {
      pathParams,
      ...rest
    } = options ?? {};
    return this.client.delete(`${this.endpoint({
      pathParams
    })}/${id}`, this.getReqOptions(rest)).pipe(map(this.responseTransformer.transform));
  }
  request(method, url, options) {
    const {
      pathParams,
      ...rest
    } = options ?? {};
    return this.client.request(method, compact([this.endpoint({
      pathParams
    }), url]).join('/'), this.getReqOptions(rest));
  }
  getReqOptions(options) {
    const {
      httpHeaders = {},
      withCredentials = true
    } = this.config;
    const {
      headers = {},
      params = {}
    } = options ?? {};
    return {
      withCredentials,
      ...options,
      params: CrmHttpParamTransform.transform(omitBy(params, isNil), this.httpEncoder),
      headers: {
        ...httpHeaders,
        ...headers
      }
    };
  }
}
class CrmBaseTransformer {
  transform(data) {
    return data;
  }
}
const CrmStandaloneEndpointConfigToken = new InjectionToken('CrmStandaloneEndpointConfigToken');
class CrmEndpointService {
  constructor() {
    this._endpoints = new Map();
    this.inj = inject(Injector);
    this.configService = inject(CrmConfigService);
    this.client = inject(CrmNetworkClientToken);
    this.httpEncoder = inject(CrmHttpParameterCodecToken, {
      optional: true
    });
  }
  create(endpointOptions) {
    if (endpointOptions.reset) {
      this.delete(endpointOptions);
    }
    const existing = this.get(endpointOptions);
    if (existing) {
      return existing;
    }
    const config = this.getConfig(endpointOptions);
    const options = this.getEndpointOptions(config, endpointOptions);
    let endpointInstance;
    if (options.endpointToken) {
      endpointInstance = this.inj.get(options.endpointToken);
    } else {
      options.endpoint = options.endpoint || CrmEndpoint;
      let requestTransformer;
      if (options.requestTransformerToken) {
        requestTransformer = this.inj.get(options.requestTransformerToken);
      } else {
        requestTransformer = new (options.requestTransformer || CrmBaseTransformer)();
      }
      let responseTransformer;
      if (options.responseTransformerToken) {
        responseTransformer = this.inj.get(options.responseTransformerToken);
      } else {
        responseTransformer = new (options.responseTransformer || CrmBaseTransformer)();
      }
      endpointInstance = new options.endpoint({
        config,
        options,
        client: this.client,
        httpEncoder: this.httpEncoder,
        requestTransformer,
        responseTransformer
      });
    }
    this._endpoints.set(this.getEndpointName(options), endpointInstance);
    return endpointInstance;
  }
  get(options) {
    return this._endpoints.get(this.getEndpointName(options));
  }
  delete(options) {
    return this._endpoints.delete(this.getEndpointName(options));
  }
  getEndpointName(endpoint) {
    return `${endpoint.configName}__${endpoint.endpointName}`;
  }
  getConfig(options) {
    const endpoints = this.configService.getConfig('modules.endpoint')?.endpoints ?? [];
    const endpointConfig = endpoints.find(config => config.name === options.configName);
    if (!endpointConfig) {
      throw new Error(`Endpoint config for '${options.endpointName}' with name '${options.configName}' not found!`);
    }
    return endpointConfig;
  }
  getEndpointOptions(config, options) {
    const {
      endpointName,
      configName
    } = options;
    const configEndpoint = config.endpointOptions?.find(endpoint => endpoint.endpointName === endpointName);
    if (configEndpoint) {
      return assign(cloneDeep(configEndpoint), options);
    }
    const standaloneEndpoints = this.inj.get(CrmStandaloneEndpointConfigToken, []);
    const standaloneEndpoint = standaloneEndpoints?.find(endpoint => endpoint.endpointName === endpointName && endpoint.configName === configName);
    if (standaloneEndpoint) {
      return assign(cloneDeep(standaloneEndpoint), options);
    }
    return options;
  }
  static {
    this.ɵfac = function CrmEndpointService_Factory(t) {
      return new (t || CrmEndpointService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmEndpointService,
      factory: CrmEndpointService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmEndpointService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const CrmEndpointDecorator = options => (target, name) => {
  Object.defineProperty(target, name, {
    get: () => CrmEndpointService.instance.create(options),
    enumerable: true,
    configurable: true
  });
};
class CrmEndpointModule {
  static forRoot(config) {
    return {
      ngModule: CrmEndpointModule,
      providers: [{
        provide: CrmModuleConfigToken,
        useValue: {
          endpoint: config
        },
        multi: true
      }, {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [CrmEndpointService],
        useFactory: instance => () => {
          CrmEndpointService.instance = instance;
        }
      }]
    };
  }
  static {
    this.ɵfac = function CrmEndpointModule_Factory(t) {
      return new (t || CrmEndpointModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmEndpointModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CrmNetworkClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmEndpointModule, [{
    type: NgModule,
    args: [{
      imports: [CrmNetworkClientModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmBaseTransformer, CrmEndpoint, CrmEndpointDecorator, CrmEndpointModule, CrmEndpointService, CrmStandaloneEndpointConfigToken };
