import { UpperCasePipe, registerLocaleData } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, inject, Component, ChangeDetectionStrategy, HostBinding, LOCALE_ID, isDevMode, APP_INITIALIZER, ViewContainerRef, TemplateRef, Directive, NgModule, Pipe } from '@angular/core';
import { NzDropDownDirective, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { CrmConfigService, mergeDeep, CrmModuleConfigToken, CrmUnsubscribeDirective } from 'common-module/core';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { of, BehaviorSubject } from 'rxjs';
import { tap, map, switchMap, filter } from 'rxjs/operators';
import { format } from 'date-fns';
import en from '@angular/common/locales/en';
import { enUS } from 'date-fns/locale';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
export { translate as crmTranslate, translateObject as crmTranslateObject } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { crmMarkForCheckFn } from 'common-module/core/util';
function CrmLocaleSelectorComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzType", "flags:" + ctx_r0.currentLocale);
  }
}
function CrmLocaleSelectorComponent_For_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 5);
    i0.ɵɵlistener("click", function CrmLocaleSelectorComponent_For_8_Template_li_click_0_listener() {
      const locale_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.useLocale(locale_r3));
    });
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵelement(2, "i", 2);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "uppercase");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const locale_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("nzType", "flags:" + locale_r3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 2, locale_r3), " ");
  }
}
const CrmTranslateAdapterToken = new InjectionToken('CrmTranslateAdapterToken');
/**
 * Abstract class for translation facade service.
 */
class CrmTranslateAdapter {
  get ready$() {
    if (!this._ready$) {
      throw new Error('CrmTranslateAdapter not initialized!');
    }
    return this._ready$.asObservable();
  }
  init() {
    return of(null).pipe(tap(() => {
      this._ready$ = new BehaviorSubject(true);
    }));
  }
  /**
   * Get translation instantly, not guaranteed that translation file is loaded in time of call.
   */
  get(key, _context) {
    return key;
  }
  /**
   * Get translation asynchronously when translation file is loaded.
   */
  get$(key, _context) {
    return of(key);
  }
  setDefaultLang(_lang) {}
  setActiveLang(_lang) {}
  getDefaultLang() {
    return 'en';
  }
  getActiveLang() {
    return 'en';
  }
  getAvailableLangs() {
    return ['en'];
  }
  static {
    this.ɵfac = function CrmTranslateAdapter_Factory(t) {
      return new (t || CrmTranslateAdapter)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmTranslateAdapter,
      factory: CrmTranslateAdapter.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslateAdapter, [{
    type: Injectable
  }], null, null);
})();

/**
 * Translation facade for translation services.
 * Service used with different translate adapters provided via CrmTranslateAdapterToken to use different
 * translate libraries.
 */
class CrmTranslateService {
  constructor() {
    this.adapter = inject(CrmTranslateAdapterToken);
  }
  get(message, context) {
    if (typeof message === 'string') {
      return this.adapter.get(message, context);
    }
    return this.adapter.get(message.message, message.context);
  }
  get$(message, context) {
    if (typeof message === 'string') {
      return this.adapter.get$(message, context);
    }
    return this.adapter.get$(message.message, message.context);
  }
  setDefaultLang(lang) {
    this.adapter.setDefaultLang(lang);
  }
  setActiveLang(lang) {
    this.adapter.setActiveLang(lang);
  }
  getDefaultLang() {
    return this.adapter.getDefaultLang();
  }
  getActiveLang() {
    return this.adapter.getActiveLang();
  }
  getAvailableLangs() {
    return this.adapter.getAvailableLangs();
  }
  ready$() {
    return this.adapter.ready$;
  }
  static {
    this.ɵfac = function CrmTranslateService_Factory(t) {
      return new (t || CrmTranslateService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmTranslateService,
      factory: CrmTranslateService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslateService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Injection token for locale service
 */
const CrmLocaleServiceToken = new InjectionToken('CrmLocaleServiceToken');
/**
 * Local storage locale key prefix
 */
const CrmLocaleKey = '_crm_locale';
/**
 * Locale service responsible for select current locale and store selected locale in local storage
 */
class CrmLocaleService {
  constructor() {
    /**
     * Injected translate service
     *
     * @protected
     */
    this.ts = inject(CrmTranslateService);
    /**
     * Injected nz i18n service
     *
     * @protected
     */
    this.i18n = inject(NzI18nService);
    /**
     * Injected config service
     *
     * @protected
     */
    this.cs = inject(CrmConfigService);
    /**
     * Resolved locale config for this service
     *
     * @protected
     */
    this.config = this.cs.getConfig('modules.translate.locale');
    /**
     * Resolved default locale by resolved config
     */
    this.defaultLocale = this.config?.defaultLocale ?? 'en';
  }
  /**
   * Resolved locales by resolved config
   */
  locales() {
    const locales = this.config?.locales;
    return locales ? Object.keys(locales) : [this.defaultLocale];
  }
  /**
   * Initialize locale service, primarily used in app providers
   */
  init() {
    return this.loadLocale().pipe(tap(locale => this.setLocale(locale)));
  }
  /**
   * Current locale getter
   */
  currentLocale() {
    return this.ts.getActiveLang();
  }
  /**
   * Use locale method, sets current locale if locale parameter is different from current locale
   *
   * @param locale
   */
  useLocale(locale) {
    if (this.currentLocale() === locale) {
      return;
    }
    this.setLocale(locale);
  }
  /**
   * Method which resolves current selected locale
   */
  loadLocale() {
    return of(localStorage.getItem(this.getLocaleKey()) ?? this.defaultLocale);
  }
  /**
   * Save selected locale in storage
   *
   * @param locale
   */
  saveLocale(locale) {
    localStorage.setItem(this.getLocaleKey(), locale);
  }
  /**
   * Set locale method, is responsible to set all required services with current locale setup
   *
   * @param locale
   * @private
   */
  setLocale(locale) {
    const config = this.config;
    if (config) {
      this.saveLocale(locale);
      this.ts.setActiveLang(locale);
      const {
        nzLocale,
        dateFnsLocale
      } = config.locales?.[locale] ?? {};
      if (nzLocale) {
        this.i18n.setLocale(nzLocale);
      }
      if (dateFnsLocale) {
        this.i18n.setDateLocale(dateFnsLocale);
      }
    }
  }
  /**
   * Resolve current locale key for storing locale in storage
   *
   * @private
   */
  getLocaleKey() {
    const {
      projectName = ''
    } = this.cs.getConfig('environment');
    return `${CrmLocaleKey}_${projectName.replace('\\s+', '')}`;
  }
  static {
    this.ɵfac = function CrmLocaleService_Factory(t) {
      return new (t || CrmLocaleService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmLocaleService,
      factory: CrmLocaleService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLocaleService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Locale component used for language selection defined in locale config
 *
 * @see {CrmLocaleConfig}
 */
class CrmLocaleSelectorComponent {
  constructor() {
    this.hostClass = 'crm-locale-selector';
    this.localeService = inject(CrmLocaleServiceToken);
  }
  ngOnInit() {
    this.locales = this.localeService.locales();
    this.currentLocale = this.localeService.currentLocale();
  }
  useLocale(locale) {
    if (this.currentLocale === locale) {
      return;
    }
    this.localeService.useLocale(locale);
    this.currentLocale = locale;
  }
  static {
    this.ɵfac = function CrmLocaleSelectorComponent_Factory(t) {
      return new (t || CrmLocaleSelectorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmLocaleSelectorComponent,
      selectors: [["crm-locale-selector"]],
      hostVars: 2,
      hostBindings: function CrmLocaleSelectorComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 5,
      consts: [["menu", "nzDropdownMenu"], ["nz-dropdown", "", "nzPlacement", "bottomCenter", "nzOverlayClassName", "crm-locale-selector--dropdown", 1, "crm-locale-selector--current", 3, "nzDropdownMenu"], ["nz-icon", "", "nzTheme", "fill", 3, "nzType"], ["nz-menu", ""], ["nz-menu-item", ""], ["nz-menu-item", "", 3, "click"], [1, "crm-locale-selector--dropdown-item"]],
      template: function CrmLocaleSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtemplate(1, CrmLocaleSelectorComponent_Conditional_1_Template, 1, 1, "i", 2);
          i0.ɵɵtext(2);
          i0.ɵɵpipe(3, "uppercase");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "nz-dropdown-menu", null, 0)(6, "ul", 3);
          i0.ɵɵrepeaterCreate(7, CrmLocaleSelectorComponent_For_8_Template, 5, 4, "li", 4, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const menu_r4 = i0.ɵɵreference(5);
          i0.ɵɵproperty("nzDropdownMenu", menu_r4);
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.currentLocale ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 3, ctx.currentLocale), " ");
          i0.ɵɵadvance(5);
          i0.ɵɵrepeater(ctx.locales);
        }
      },
      dependencies: [UpperCasePipe, NzDropDownDirective, NzDropdownMenuComponent, NzMenuDirective, NzMenuItemComponent, NzIconDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLocaleSelectorComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-locale-selector',
      template: `
    <div
      class="crm-locale-selector--current"
      nz-dropdown
      [nzDropdownMenu]="menu"
      nzPlacement="bottomCenter"
      nzOverlayClassName="crm-locale-selector--dropdown"
    >
      @if (currentLocale) {
        <i nz-icon [nzType]="'flags:' + currentLocale" nzTheme="fill"></i>
      }
      {{ currentLocale | uppercase }}
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        @for (locale of locales; track locale) {
          <li nz-menu-item (click)="useLocale(locale)">
            <div class="crm-locale-selector--dropdown-item">
              <i nz-icon [nzType]="'flags:' + locale" nzTheme="fill"></i>
              {{ locale | uppercase }}
            </div>
          </li>
        }
      </ul>
    </nz-dropdown-menu>
  `,
      imports: [UpperCasePipe, NzDropDownDirective, NzDropdownMenuComponent, NzMenuDirective, NzMenuItemComponent, NzIconDirective],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Default translate config object
 */
const defaultTranslateConfig = {
  loader: {
    remoteLocale: false,
    remoteEnvs: [],
    localeFolder: './assets/locales/',
    remoteLocalePath: 'content',
    remoteLocaleUrlParams: {
      key: 'i18n'
    }
  },
  locale: {
    defaultLocale: 'en',
    locales: {
      en: {
        ngLocale: en,
        nzLocale: en_US,
        dateFnsLocale: enUS
      }
    }
  },
  formatter: {
    date: {
      defaultFormat: 'P'
    }
  }
};

/**
 * Injection token for formatter service
 */
const CrmFormatterServiceToken = new InjectionToken('CrmFormatterServiceToken');
/**
 * Default implementation of formatter service
 */
class CrmFormatterService {
  constructor() {
    /**
     * Injected locale service
     *
     * @protected
     */
    this.localeService = inject(CrmLocaleServiceToken);
    /**
     * Injected config service
     *
     * @protected
     */
    this.configService = inject(CrmConfigService);
    /**
     * Resolved configuration object for this service
     */
    this.config = this.configService.mergeConfig(defaultTranslateConfig.formatter ?? {}, 'modules.translate.formatter');
  }
  /**
   * Format date method which formats date by configuration and current locale
   */
  formatDate(value, dateFormat) {
    const locales = this.localeService.config.locales ?? {};
    const locale = locales[this.localeService.currentLocale()]?.dateFnsLocale;
    return format(value, dateFormat ?? this.config?.date?.defaultFormat ?? 'P', {
      locale
    });
  }
  static {
    this.ɵfac = function CrmFormatterService_Factory(t) {
      return new (t || CrmFormatterService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmFormatterService,
      factory: CrmFormatterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormatterService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Locale ID provider, used to override angular LOCALE_ID provider
 *
 * Overrides toString method with current selected locale
 */
class CrmLocaleIdProvider extends String {
  constructor() {
    super(...arguments);
    this.localeService = inject(CrmLocaleServiceToken);
  }
  toString() {
    return this.localeService.currentLocale();
  }
  valueOf() {
    return this.toString();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmLocaleIdProvider_BaseFactory;
      return function CrmLocaleIdProvider_Factory(t) {
        return (ɵCrmLocaleIdProvider_BaseFactory || (ɵCrmLocaleIdProvider_BaseFactory = i0.ɵɵgetInheritedFactory(CrmLocaleIdProvider)))(t || CrmLocaleIdProvider);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmLocaleIdProvider,
      factory: CrmLocaleIdProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLocaleIdProvider, [{
    type: Injectable
  }], null, null);
})();

/**
 * Transloco adapter, extends translate adapter with specific logic of handling methods
 */
class CrmTranslocoAdapter extends CrmTranslateAdapter {
  constructor() {
    super(...arguments);
    /**
     * Injected transloco service
     *
     * @protected
     */
    this.service = inject(TranslocoService);
  }
  /**
   * Initialize lang data for transloco
   */
  init() {
    const lang = this.service.getActiveLang();
    return this.service.load(lang).pipe(tap(() => {
      const isActiveLoaded = this.isLangLoaded(lang);
      this._ready$ = new BehaviorSubject(isActiveLoaded);
      this.service.events$.pipe(map(({
        type,
        payload
      }) => {
        if (type === 'langChanged') {
          return this.isLangLoaded(payload.langName);
        }
        return type === 'translationLoadSuccess';
      })).subscribe(this._ready$);
    }));
  }
  get$(key, context) {
    return this.service.selectTranslate(key, context);
  }
  get(key, context) {
    return this.service.translate(key, context);
  }
  setDefaultLang(lang) {
    this.service.setDefaultLang(lang);
  }
  setActiveLang(lang) {
    this.service.setActiveLang(lang);
  }
  getDefaultLang() {
    return this.service.getDefaultLang();
  }
  getActiveLang() {
    return this.service.getActiveLang();
  }
  getAvailableLangs() {
    return this.service.getAvailableLangs();
  }
  isLangLoaded(lang) {
    return this.service.getTranslation().has(lang);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmTranslocoAdapter_BaseFactory;
      return function CrmTranslocoAdapter_Factory(t) {
        return (ɵCrmTranslocoAdapter_BaseFactory || (ɵCrmTranslocoAdapter_BaseFactory = i0.ɵɵgetInheritedFactory(CrmTranslocoAdapter)))(t || CrmTranslocoAdapter);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmTranslocoAdapter,
      factory: CrmTranslocoAdapter.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslocoAdapter, [{
    type: Injectable
  }], null, null);
})();
const crmIsRemoteTranslations = configService => {
  const {
    env
  } = configService.getConfig('environment');
  const {
    remoteLocale = false,
    remoteEnvs = []
  } = configService.getConfig('modules.translate.loader');
  return remoteLocale && env && remoteEnvs.includes(env);
};
const crmLoadRemoteTranslations = (http, configService, lang) => {
  const endpoint = configService.mergeConfig({
    endpoints: []
  }, 'modules.endpoint');
  const crmEndpoint = endpoint?.endpoints?.find(ep => ep.name === 'crm');
  if (!crmEndpoint) {
    return of({});
  }
  const {
    baseUrl,
    httpHeaders,
    withCredentials
  } = crmEndpoint;
  const {
    remoteLocaleUrl = baseUrl,
    remoteLocaleUrlParams = {},
    remoteLocalePath = ''
  } = configService.getConfig('modules.translate.loader');
  return http.get([remoteLocaleUrl, remoteLocalePath].join('/'), {
    headers: httpHeaders,
    withCredentials,
    params: {
      locale: lang,
      $limit: '1000',
      ...resolveParams(remoteLocaleUrlParams)
    }
  }).pipe(map(({
    data
  }) => data),
  // TBD: maybe merge JSONs if there is more than one result?
  map(res => res[0]?.value ?? {}));
};
const resolveParams = params => {
  return Object.entries(params).reduce((p, [k, v]) => ({
    ...p,
    [k]: typeof v === 'function' ? v() : v ?? null
  }), {});
};

/**
 * Service which overrides transloco loader
 */
class CrmTranslocoLoader {
  constructor() {
    /**
     * Injected http client service
     *
     * @protected
     */
    this.http = inject(HttpClient);
    /**
     * Injected config service
     *
     * @protected
     */
    this.configService = inject(CrmConfigService);
  }
  getTranslation(lang) {
    if (crmIsRemoteTranslations(this.configService)) {
      return crmLoadRemoteTranslations(this.http, this.configService, lang);
    }
    const {
      version
    } = this.configService.getConfig('environment');
    const {
      localeFolder
    } = this.configService.getConfig('modules.translate.loader');
    return this.http.get([localeFolder, `${lang}.json?v=${version}`].join(''));
  }
  static {
    this.ɵfac = function CrmTranslocoLoader_Factory(t) {
      return new (t || CrmTranslocoLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmTranslocoLoader,
      factory: CrmTranslocoLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslocoLoader, [{
    type: Injectable
  }], null, null);
})();

/**
 * Check if input value is type of CrmTranslateMessageWithContext
 *
 * @see {CrmTranslateMessageWithContext}
 *
 * @param value
 */
const crmIsTranslateMessageWithContext = value => !!value && typeof value === 'object' && 'message' in value;

/**
 * Check if input value is type of CrmTranslateMessage
 *
 * @see {CrmTranslateMessage}
 *
 * @param value
 */
const crmIsTranslateMessage = value => typeof value === 'string' || crmIsTranslateMessageWithContext(value);

/**
 * Function which sets and returns translate providers
 *
 * @usageNotes
 *
 * In providers of `NgModule` or `bootstrapApplication`
 *
 * ```ts
 * providers: [crmProvideTranslate(config)]
 * ```
 *
 * @see {CrmTranslateConfig, TranslocoConfig}
 *
 * @param config - CRM translate configuration, default {@see {defaultTranslateConfig}}
 * @param transloco - partial transloco configuration object
 */
const crmProvideTranslate = (config, transloco) => {
  const mergedConfig = mergeDeep(defaultTranslateConfig, config ?? {});
  const locales = mergedConfig.locale?.locales;
  const defaultLocale = mergedConfig.locale?.defaultLocale ?? 'en';
  const availableLangs = locales ? Object.keys(locales) : [defaultLocale];
  Object.values(locales ?? {}).forEach(({
    ngLocale
  }) => {
    if (ngLocale) {
      registerLocaleData(ngLocale);
    }
  });
  return [CrmTranslateService, {
    provide: CrmModuleConfigToken,
    useValue: {
      translate: mergedConfig
    },
    multi: true
  }, {
    provide: CrmLocaleServiceToken,
    useClass: CrmLocaleService
  }, {
    provide: LOCALE_ID,
    useClass: CrmLocaleIdProvider
  }, {
    provide: CrmFormatterServiceToken,
    useClass: CrmFormatterService
  }, provideTransloco({
    loader: CrmTranslocoLoader,
    config: {
      prodMode: !isDevMode(),
      availableLangs,
      defaultLang: defaultLocale,
      fallbackLang: defaultLocale,
      missingHandler: {
        logMissingKey: false
      },
      flatten: {
        aot: !isDevMode()
      },
      ...(transloco ?? {})
    }
  }), provideTranslocoMessageformat(), {
    provide: CrmTranslateAdapterToken,
    useClass: CrmTranslocoAdapter
  }, {
    provide: APP_INITIALIZER,
    useFactory: (localeService, adapter) => {
      return () => localeService.init().pipe(switchMap(() => adapter.init()));
    },
    deps: [CrmLocaleServiceToken, CrmTranslateAdapterToken],
    multi: true
  }];
};
class CrmTranslateDirective extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.viewContainerRef = inject(ViewContainerRef);
    this.templateRef = inject(TemplateRef);
    this.service = inject(CrmTranslateService);
  }
  ngOnInit() {
    this.untilDestroyed(this.service.ready$()).pipe(filter(ready => ready)).subscribe(() => this.setView());
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.view?.destroy();
    this.view = null;
  }
  refresh() {
    this.view?.detectChanges();
  }
  setView() {
    if (!this.view) {
      this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, {
        $implicit: value => this.service.get(value)
      });
      this.view.detach();
    }
    this.refresh();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmTranslateDirective_BaseFactory;
      return function CrmTranslateDirective_Factory(t) {
        return (ɵCrmTranslateDirective_BaseFactory || (ɵCrmTranslateDirective_BaseFactory = i0.ɵɵgetInheritedFactory(CrmTranslateDirective)))(t || CrmTranslateDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmTranslateDirective,
      selectors: [["", "crmTranslate", ""]],
      exportAs: ["crmTranslate"],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslateDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmTranslate]',
      exportAs: 'crmTranslate'
    }]
  }], null, null);
})();

/**
 * Translate module with possibility to override config and transloco config with static `forRoot` method
 *
 * @usageNotes
 *
 * For module app
 *
 * ```ts
 * @NgModule({
 *   imports: [
 *     CrmTranslateModule.forRoot(config, translocoConfig)
 *   ]
 * })
 * class AppModule {}
 * ```
 *
 * For standalone app
 *
 * ```ts
 * bootstrapApplication(AppComponent, {
 *   providers: [
 *     importProvidersFrom(CrmTranslateModule.forRoot(config, translocoConfig))
 *   ]
 * })
 * ```
 */
class CrmTranslateModule {
  static forRoot(config, transloco) {
    return {
      ngModule: CrmTranslateModule,
      providers: crmProvideTranslate(config, transloco)
    };
  }
  static {
    this.ɵfac = function CrmTranslateModule_Factory(t) {
      return new (t || CrmTranslateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmTranslateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslateModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Translate pipe
 *
 * Use this pipe in template to translate key
 */
class CrmTranslatePipe {
  constructor() {
    this.service = inject(CrmTranslateService);
    this.markForCheckFn = crmMarkForCheckFn();
  }
  transform(value) {
    if (!value) {
      return '';
    }
    this.service.get$(value).subscribe(result => {
      this.value = result;
      this.markForCheckFn();
    });
    return this.value;
  }
  static {
    this.ɵfac = function CrmTranslatePipe_Factory(t) {
      return new (t || CrmTranslatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmTranslate",
      type: CrmTranslatePipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTranslatePipe, [{
    type: Pipe,
    args: [{
      name: 'crmTranslate',
      standalone: true,
      pure: false
    }]
  }], null, null);
})();

/**
 * Pipe which checks if input value is type of `CrmTranslateMessage`
 */
class CrmIsTranslateMessagePipe {
  transform(value) {
    return crmIsTranslateMessage(value) ? value : undefined;
  }
  static {
    this.ɵfac = function CrmIsTranslateMessagePipe_Factory(t) {
      return new (t || CrmIsTranslateMessagePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsTranslateMessage",
      type: CrmIsTranslateMessagePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsTranslateMessagePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsTranslateMessage'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFormatterService, CrmFormatterServiceToken, CrmIsTranslateMessagePipe, CrmLocaleIdProvider, CrmLocaleKey, CrmLocaleSelectorComponent, CrmLocaleService, CrmLocaleServiceToken, CrmTranslateAdapter, CrmTranslateAdapterToken, CrmTranslateDirective, CrmTranslateModule, CrmTranslatePipe, CrmTranslateService, CrmTranslocoAdapter, CrmTranslocoLoader, crmIsRemoteTranslations, crmIsTranslateMessage, crmIsTranslateMessageWithContext, crmLoadRemoteTranslations, crmProvideTranslate, defaultTranslateConfig };
