import { inject, ChangeDetectorRef } from '@angular/core';

const crmDetectChangesFn = () => {
    const ref = inject(ChangeDetectorRef);
    return () => ref.detectChanges();
};

const crmMarkForCheckFn = () => {
    const ref = inject(ChangeDetectorRef);
    return () => ref.markForCheck();
};

/**
 * Generated bundle index. Do not edit.
 */

export { crmDetectChangesFn, crmMarkForCheckFn };

